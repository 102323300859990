import React, { useState, useEffect, useContext } from "react";
import useJobnetAuth from "../hooks/useJobnetAuth";
import useRoamingSettings from "../hooks/useRoamingSettings";

const UserContext = React.createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const { roamingSettings } = useRoamingSettings();
  const { jobnetAuth } = useJobnetAuth();

  const [userContext, setUserContext] = useState({
    isLoggedIn: false,
    access_token: null
  });


  useEffect(() => {
    const access_token = roamingSettings.get("access_token");
    const refresh_token = roamingSettings.get("refresh_token");
    const username = roamingSettings.get("username");
    const password = roamingSettings.get("password");

    (async () => {
      const testResult = await jobnetAuth.test(access_token);
      if (testResult.status === "OK") {
        setUserContext({
          isLoggedIn: true,
          access_token: access_token,
          refresh_token: refresh_token,
        });
      } else if (testResult.error_code === 3) {
        const refreshResult = await jobnetAuth.refresh(refresh_token);
        if (refreshResult.status === "OK") {
          setUserContext({
            isLoggedIn: true,
          });
        } else if (username && password) {
          const loginResult = await jobnetAuth.login(username, password);
          if (loginResult.status === 'OK') {
            setUserContext({
              isLoggedIn: true,
              access_token: loginResult.access_token,
              refresh_token: loginResult.refresh_token,
            });
          }
        }
      }
    })();
  }, [userContext.isLoggedIn]);


  const onAuthMissing = () => {
    setUserContext(pre =>
    {
      return {...userContext, isLoggedIn: false}
     })
   }

  const onLoginSuccess = async (userObject, username, password) => {
    setUserContext((pre) => {
      return { isLoggedIn: true, ...userObject };
    });

    roamingSettings.setPersistentAsync('access_token', userObject.access_token);
    roamingSettings.setPersistentAsync('refresh_token', userObject.refresh_token);

    if (username && password) {
      roamingSettings.setPersistentAsync('username', username);
      roamingSettings.setPersistentAsync('password', password);
    }
  };

  return (
    <UserContext.Provider value={{ userContext, onLoginSuccess, onAuthMissing }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserContextConsumer = UserContext.Consumer;
