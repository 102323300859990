import {VirtualizedComboBox } from '@fluentui/react';


const  VirtualizedCombobox = ({
  label,
  values,
  name,
  handleChange,
  identifier,
  disabled,
}) =>  {

const onChange = (event, item) => {
    item.selected = true;
    handleChange('single', identifier, name, item);
};
    
  return (
    <VirtualizedComboBox
      label={label}
      autoComplete="on"
      allowFreeform
      options={values.options.map((option) => ({
        key: option.id,
        text: option.text,
      }))}
      dropdownMaxWidth={200}
    useComboBoxAsMenuWidth
      onChange={onChange}
      selectedKey={values.selectedKeys[0] ? values.selectedKeys[0] : undefined}
    />
  );
};

export default VirtualizedCombobox;