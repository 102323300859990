import {
  Stack,
  Label,
  // getTheme,
  TextField,
  DefaultButton,
} from "@fluentui/react";
import { useRef, useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import useJobnetAuth from "../hooks/useJobnetAuth";
import ErrorBar from "./ErrorBar";

// const theme = getTheme();

function Login({ logoURL, title }) {
  const { onLoginSuccess } = useUserContext();
  const { jobnetAuth } = useJobnetAuth();

  //Styling
  // const stackItemStyles = {
  //   root: {
  //     padding: 5,
  //   },
  // };
  const verticalGapStackTokens = {
    childrenGap: 10,
    padding: 20,
  };

  //Business Logic
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState({ isTrue: false, message: null });

  const formRef = useRef();

  const emptyInputs = () => {
    setUsername((pre) => "");
    setPassword((pre) => "");
  };

  const handleLoginClick = async (e) => {
    e.preventDefault();
    if (formRef.current.checkValidity()) {
      const result = await jobnetAuth.login(username, password);

      if (result.status === "OK") {
        emptyInputs();
        await onLoginSuccess(result, username, password);
      }

      if (result.status === "ERROR") {
        setHasError({ isTrue: true, message: result.message });
        emptyInputs();
      }
    } else {
      formRef.current.reportValidity();
    }
  };

  return (
    <form ref={formRef} onSubmit={handleLoginClick} autoComplete="off">
      <Stack tokens={verticalGapStackTokens}>
        <Stack.Item align="center">
          <Label>
            <img src={logoURL} width="200" alt={title}></img>
          </Label>
        </Stack.Item>
        <Stack.Item align="left">
          <Label>Logindaten für CRM:</Label>
        </Stack.Item>
        <Stack.Item align="left">
          <TextField
            required
            placeholder="Bspw. tom.test@jobnet.ag"
            label="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Stack.Item>
        <Stack.Item align="left">
          <TextField
            required
            placeholder="Bitte Passwort eingeben"
            label="Passwort"
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack.Item>
        <Stack.Item align="center">
          <DefaultButton text="Login" type="submit" />
        </Stack.Item>
        {hasError.isTrue && (
          <ErrorBar
            message={hasError.message}
            onDismiss={() => setHasError({ isTrue: false, message: null })}
          />
        )}
      </Stack>
    </form>
  );
}

export default Login;
