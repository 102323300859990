import { Stack, Checkbox} from '@fluentui/react';
import { useState } from 'react';


function Attachment({
    name,
    id,
    attachmentType,
    handleChange


}) {

    const [isChecked, setIsChecked] = useState(true)

    const onChange = () => {
        handleChange(id)
        setIsChecked(pre =>  !pre)

     } 

    
    if (attachmentType === 'file') {
        return (
            <Stack>
                <Checkbox label={name} checked={isChecked} onChange={onChange} />
            </Stack>
        )
    } else {
        return(
            <Checkbox label={name} checked={false} onChange={onChange} disabled /> 
        )
     }

}

export default Attachment;