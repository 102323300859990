import {MessageBar, MessageBarType} from "@fluentui/react";

function ErrorBar({message, onDismiss, isMultiline = false}) {
    switch (message) {
        case 'authentication_failed':
            message = 'Login fehlgeschlagen, Benutzername oder Passwort falsch.';
            break;
        default:
            message = 'Unbekannter Fehler: ' + message;
    }

    return (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={isMultiline}
            onDismiss={onDismiss}
            dismissButtonAriaLabel="Close"
        >
            {message}
        </MessageBar>
    );
}

export default ErrorBar;
