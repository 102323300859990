import {
  OfficeContextConsumer,
  OfficeContextProvider,
} from "../contexts/OfficeContext";

import {
  UserContextProvider,
  UserContextConsumer,
} from "../contexts/UserContext";
import Loading from "./Loading";
import Login from "./Login";
import OuterForm from "./OuterForm";

import { initializeIcons } from "@fluentui/react";

function App() {
  initializeIcons();

  return (
    <OfficeContextProvider>
      <OfficeContextConsumer>
        {({ isInitialized }) => {
          if (!isInitialized) {
            return <Loading />;
          } else if (isInitialized) {
            return (
              <UserContextProvider>
                <UserContextConsumer>
                  {({ userContext }) => {
                    if (userContext.isLoggedIn) {
                      return <OuterForm />;
                    } else {
                      return (
                        <Login
                          title="Jobnet.AG CRM-Connector"
                          logoURL="/logo.svg"
                        />
                      );
                    }
                  }}
                </UserContextConsumer>
              </UserContextProvider>
            );
          }
        }}
      </OfficeContextConsumer>
    </OfficeContextProvider>
  );
}

export default App;
