function useJobnetApi(props) {

  const findMessageById = async (messageId, access_token, onAuthMissing) => {
    try {
      const res = await fetch(`https://api.job-server.net/v1/crm/contacthistory/${messageId}`, {
        mode: 'no-cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        method: 'GET',
      });

      if (res.status === 200) {
        const data = await res.json();
        return data;
      } else if (res.status === 401) {
        onAuthMissing()
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMessageDataByIdentifier = async (
    identifier,
    allAddresses,
    access_token,
    companyKey, onAuthMissing
  ) => {
    let reqBody;
    if (companyKey) {
      reqBody = JSON.stringify({
        email: identifier,
        all_emails: allAddresses,
        company: companyKey,
      });
    } else {
      reqBody = JSON.stringify({
        email: identifier,
        all_emails: allAddresses,
      });
    }
    try {
      const res = await fetch('https://api.job-server.net/v1/crm/contact', {
        mode: 'no-cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        method: 'POST',
        body: reqBody,
      });

      if (res.status === 200) {
        const data = await res.json();
        return { identifier: identifier, values: data };
      } else if (res.status === 401) {
        onAuthMissing()
      } else {
        console.log(res.json());
        return {};
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postToContactHistory = async (body, access_token, onAuthMissing) => {
    const reqBody = JSON.stringify(body);

    try {
      const res = await fetch('https://api.job-server.net/v1/crm/contacthistory', {
        mode: 'no-cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        method: 'POST',
        body: reqBody,
      });
      if (res.status === 200) {
        const data = await res.json();
        return data;
      } else if (res.status === 401) {
        onAuthMissing()
      }
      else {
      }
    } catch (e) {
      console.log('Error while posting:', e);
    }
  };

  return {
    jobnetAPI: {
      findMessageById,
      getMessageDataByIdentifier,
      postToContactHistory,
    },
  };
}
export default useJobnetApi;
