import { Dropdown } from '@fluentui/react/lib/Dropdown';

const MultiSelectDropdown = ({
  label,
  values,
  name,
  handleChange,
  identifier,
  disabled,
}) => {
  const onChange = (event, item) => {
    handleChange('multi', identifier, name, item);
  };

  return (
    <Dropdown
      disabled={disabled}
      label={label}
      multiSelect
      onChange={onChange}
      options={values.options.map((option) => ({
        key: option.id,
        text: option.text,
      }))}
      selectedKeys={values.selectedKeys}
    />
  );
};

export default MultiSelectDropdown;
