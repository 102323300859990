import moment from 'moment-timezone';
import {useState} from 'react';
import {useUserContext} from '../contexts/UserContext';
import {useOfficeContext} from '../contexts/OfficeContext';

import useJobnetApi from '../hooks/useJobnetApi';

function UseContactHistoryForm() {
    const [messageSubject, setMessageSubject] = useState();
    const [messageBody, setMessageBody] = useState();
    const [messageAttachments, setMessageAttachments] = useState([])
    const [messageDateTime, setMessageDateTime] = useState();
    const [formInstances, setFormInstances] = useState([]);


    const {jobnetAPI} = useJobnetApi();
    const {userContext, onAuthMissing} = useUserContext();
    const {message} = useOfficeContext();

    function onChangeSubject(e) {
        if (typeof e === 'string') return setMessageSubject(e);
        return setMessageSubject(e.target.value);
    }

    function onChangeBody(e) {
        if (typeof e === 'string') return setMessageBody(e);
        return setMessageBody(e.target.value);
    }

    function onChangeAttachments(e) {
        if (Array.isArray(e)) {
            const mappedAttachments = e.map(element => ({
                ...element,
                selectedForUploading: element.attachmentType === 'file' ? true : false
            }))
            return setMessageAttachments(mappedAttachments)
        } else if (typeof e === 'string') {
            const newState = messageAttachments.map(messageAttachment => {
                if (messageAttachment.id === e) {
                    messageAttachment.selectedForUploading = !messageAttachment.selectedForUploading
                }
                return messageAttachment
            })
            setMessageAttachments((pre) => newState)
        }

    }

    function onChangeDatetime(e) {
        const formattedDate = moment
            .tz(e, 'Europe/Berlin')
            .format('YYYY-MM-DD HH:mm:ss');
        return setMessageDateTime(formattedDate);
    }

    function createFormInstance(emailAddress, messageId, isDone = false) {
        return {
            messageId: messageId,
            identifier: emailAddress,
            isDone: isDone,
            isActive: false,
            values: {
                consultant: {
                    options: [],
                    selectedKeys: [],
                },
                company: {
                    options: [],
                    selectedKeys: [],
                },
                contact_person: {
                    options: [],
                    selectedKeys: [],
                },
                order: {
                    options: [],
                    selectedKeys: [],
                },
                acquisition: {
                    options: [],
                    selectedKeys: [],
                },
                product: {
                    options: [],
                    selectedKeys: [],
                },
            },
        };
    }

    function addFormInstances(foundAddresses, emailAddressesArr, messageId) {
        let newState = [];
        emailAddressesArr.sort().forEach((address) => {
            if (foundAddresses.includes(address)) {
                newState.push(createFormInstance(address, messageId, true));
            } else {
                newState.push(createFormInstance(address, messageId));
            }
        });
        return setFormInstances(newState);
    }

    function updateActiveInstance(identifier) {
        return () => {
            const ident = identifier;

            let newState = formInstances.map((instance) => {
                if (instance.identifier === ident) {
                    instance.isActive = true;
                } else {
                    instance.isActive = false;
                }
                return instance;
            });
            setFormInstances((pre) => newState);
        };
    }

    function updateFormInstanceValues(instanceObject) {
        const newState = formInstances.map((instance) => {
            if (instance.identifier === instanceObject.identifier) {
                instance.values = instanceObject.values;
                return instance;
            } else {
                return instance;
            }
        });
        setFormInstances((pre) => newState);
    }

    function updateFormInstanceDropdownValue(type, identifier, name, object) {
        let newState;
        if (type === 'multi') {
            newState = formInstances.map((instance) => {
                if (instance.identifier === identifier) {
                    if (object.selected) {
                        instance['values'][name]['selectedKeys'].push(object.key);
                    } else {
                        let filteredKeys = instance['values'][name]['selectedKeys'].filter(
                            (key) => key !== object.key
                        );
                        instance['values'][name]['selectedKeys'] = filteredKeys;
                    }
                    return instance;
                } else {
                    return instance;
                }
            });
        }
        if (type === 'single') {
            newState = formInstances.map((instance) => {
                if (instance.identifier === identifier) {
                    instance['values'][name]['selectedKeys'] = [object.key];
                    return instance;
                } else {
                    return instance;
                }
            });
        }
        setFormInstances((pre) => newState);
    }


    const postInstanceToServer = async (identifier) => {


        let attachment = {}

        const instance = formInstances.filter(
            (instance) => instance.identifier === identifier
        )[0];
        const values = instance['values'];

        function handleAttachmentsCallback(res, name) {
            if (res.status === "succeeded") {
                if (res.value.format === "base64") {
                    return ({[name]: res.value.content})
                } else {
                    console.log('Is not base64:', res)
                }
            }
        }

        function getAttachmentAsync(messageAttachment) {
            return new Promise((resolve, reject) => {
                try {
                    message.item.getAttachmentContentAsync(messageAttachment['id'], function (res) {
                        let nextAttachment = handleAttachmentsCallback(res, messageAttachment['name'])
                        return resolve(nextAttachment)
                    })
                } catch (e) {
                    return reject(e);
                }
            });
        }

        for (let i = 0; i < messageAttachments.length; i++) {
            if (!messageAttachments[i].selectedForUploading) continue;
            const result = await getAttachmentAsync(messageAttachments[i])
            attachment = {...attachment, ...result}
        }

        let payload = {
            id: instance.messageId,
            email: instance.identifier,
            consultant: values.consultant.selectedKeys,
            company: values.company.selectedKeys[0],
            contact_person: values.contact_person.selectedKeys,
            order: values.order.selectedKeys,
            acquisition: values.acquisition.selectedKeys,
            product: values.product.selectedKeys,
            subject: messageSubject,
            body: messageBody,
            datetime: messageDateTime,
        }

        if (Object.keys(attachment).length > 0) {
            payload = {...payload, attachment}
        }

        try {
            const data = await jobnetAPI.postToContactHistory(
                payload,
                userContext.access_token, onAuthMissing
            );
            if (data.length > 0) {
                markInstancesChecked(data)
            }
        } catch (e) {
            console.log('Could not post Instance:', e);
        }
    };


    const markInstancesChecked = (arrOfEmailadresses) => {
        const newInstances = formInstances.map(instance => {
            if (arrOfEmailadresses.indexOf(instance.identifier) > -1) {
                instance.isDone = true
                instance.isActive = false
            }
            return instance
        })
        setFormInstances((pre) => newInstances)
    }

    return {
        subject: {value: messageSubject, setValue: (e) => onChangeSubject(e)},
        body: {value: messageBody, setValue: (e) => onChangeBody(e)},
        attachments: {value: messageAttachments, setValue: (e) => onChangeAttachments(e)},
        dateTime: {value: messageDateTime, setValue: (e) => onChangeDatetime(e)},
        instances: {
            value: formInstances,
            addFormInstances,
            updateActiveInstance,
            updateFormInstanceValues,
            updateFormInstanceDropdownValue,
            postInstanceToServer,
        },
    };
}

export default UseContactHistoryForm;
