/* Hook to read and write values into the Roaming Settings. To persist entries between sessions -> use Async Methods */

/* global Excel, Office */
function useRoamingSettings() {
  function set(settingName, settingValue) {
    return Office.context.roamingSettings.set(settingName, settingValue);
  }

  function get(settingName) {
    return Office.context.roamingSettings.get(settingName);
  }

  function remove(settingName) {
    return Office.context.roamingSettings.remove(settingName);
  }

  // Set and Save on entry persistent -> 99% Cases way to go.
  const setPersistentAsync = async (settingName, settingValue) => {
    let result = "";
    set(settingName, settingValue);
    await Office.context.roamingSettings.saveAsync(function (res) {
      if (res.status === Office.AsyncResultStatus.Succeeded) {
        result = "success";
      } else {
        result = "error";
      }
    });
    return result;
  };

  // Save all persisting, e.g. before addin shutdown
  const saveAsync = async () => {
    let result = "";
    await Office.context.roamingSettings.saveAsync(function (res) {
      if (res.status === Office.AsyncResultStatus.Succeeded) {
        result = "success";
      } else {
        result = "error";
      }
    });
    return result;
  };

  return {
    roamingSettings: {
      set,
      get,
      remove,
      setPersistentAsync,
      saveAsync,
    },
  };
}

export default useRoamingSettings;
