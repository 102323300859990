/* Loads the Office Context into the application, needs to be loaded first -> isInitialized
Additional business logic inside.
update-Function is fired on item (email) change
*/

/* global Excel, Office */

import React, { useState, useEffect, useContext } from 'react';

const OfficeContext = React.createContext({ isInitialized: false });

export const useOfficeContext = () => useContext(OfficeContext);

export const OfficeContextProvider = ({ children }) => {
  const [messageContext, setMessageContext] = useState({
    isInitialized: false,
  });

  //OfficeContext is ran on first app render to initialize office.js
  useEffect(() => {
    const asyncHandler = async () => {
      //This runs on every message opening
      const update = async () => {
        const mailbox = Office.context.mailbox;
        const item = mailbox?.item;

        const getItemState = (userProfileEmailAddress) => {
          if (!item) return undefined;

          const {
            from,
            to,
            internetMessageId,
            conversationId,
            itemType,
            cc,
            body,
            subject,
            dateTimeCreated,
            attachments,
            getAttachmentContentAsync
          } = item;

          //Add direction to item based on userProfileEmail
          const direction =
            userProfileEmailAddress === from.emailAddress
              ? 'outbound'
              : 'inbound';

          //Add an Array of all relevant Addresses
          // 'inbound' -> from, to, cc
          // 'outbound' -> to, cc
          // Always exclude userProfile

          function createEmailAddressArray() {
            let allExternalRecipients = new Set();
            allExternalRecipients.add(from.emailAddress);
            to.map((el) => el.emailAddress).forEach((el) =>
              allExternalRecipients.add(el)
            );
            cc.map((el) => el.emailAddress).forEach((el) =>
              allExternalRecipients.add(el)
            );
            return Array.from(allExternalRecipients).filter(
              (el) => el !== userProfileEmailAddress
            );
          }

          const emailAddresses = createEmailAddressArray();

          return {
            from,
            to,
            cc,
            internetMessageId,
            conversationId,
            itemType,
            body,
            subject,
            dateTimeCreated,
            direction,
            emailAddresses,
            attachments,
            getAttachmentContentAsync
          
          };
        };

        const getMailboxState = () => {
          if (!mailbox) return undefined;
          const { userProfile } = mailbox;

          return {
            userProfile,
            item: getItemState(userProfile.emailAddress),
          };
        };

        setMessageContext({
          message: getMailboxState(),
          // used to prevent racing condition between app render and office.js initializing
          isInitialized: true,
        });
      };

      update();

      // Add Eventhandler for pinable Addin -> Fire update() on message change
      Office.context.mailbox &&
        Office.context.mailbox.addHandlerAsync(
          Office.EventType.ItemChanged,
          update
        );
    };

    // Initialize Office.js before moving on with asyncHandler
    // First Thing happening on application load
    Office.onReady(() => {
      asyncHandler();
    });
  }, []);

  return (
    <OfficeContext.Provider value={messageContext}>
      {children}
    </OfficeContext.Provider>
  );
};

export const OfficeContextConsumer = OfficeContext.Consumer;
