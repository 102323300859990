import {Stack, Depths, Label, PrimaryButton, Icon} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useOfficeContext} from '../contexts/OfficeContext';
import {useUserContext} from '../contexts/UserContext';
import useJobnetApi from '../hooks/useJobnetApi';
import MultiSelectDropdown from './MultiSelectDropdown';
import VirtualizedCombobox from './VirtualizedCombobox'

function InnerForm({
       instance,
       setActive,
       theKey,
       updateInstanceData,
       updateFormInstanceDropdownValue,
       postInstanceToServer,
   }) {
    const {message} = useOfficeContext();
    const {userContext, onAuthMissing} = useUserContext();
    const {jobnetAPI} = useJobnetApi();
    const [fetchedInstanceData, setFetchedInstanceData] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [lastCompanyKey, setLastCompanyKey] = useState(undefined);


    useEffect(() => {
        if (instance.isActive && fetchedInstanceData === null && !instance.isDone) {
            setIsFetching((pre) => !pre);
            (async () => {
                const data = await jobnetAPI.getMessageDataByIdentifier(
                    instance.identifier,
                    message.item.emailAddresses,
                    userContext.access_token,
                    instance.values.company.selectedKeys[0]
                        ? instance.values.company.selectedKeys[0]
                        : null, onAuthMissing
                );
                if (data.values.company.selectedKeys.length > 0) {
                    setLastCompanyKey(() => data.values.company.selectedKeys[0]);
                }
                setFetchedInstanceData((pre) => data);
                setIsFetching((pre) => !pre);
            })();
        }
    }, [instance.isActive]);

    useEffect(() => {
        if (instance.isActive && !instance.isDone) {
            if (lastCompanyKey !== instance.values.company.selectedKeys[0]) {
                setIsFetching((pre) => !pre);
                (async () => {
                    const data = await jobnetAPI.getMessageDataByIdentifier(
                        instance.identifier,
                        message.item.emailAddresses,
                        userContext.access_token,
                        instance.values.company.selectedKeys[0]
                            ? instance.values.company.selectedKeys[0]
                            : null, onAuthMissing
                    );
                    if (data.values.company.selectedKeys.length > 0) {
                        setLastCompanyKey(() => data.values.company.selectedKeys[0]);
                    }
                    setFetchedInstanceData((pre) => data);
                    setIsFetching((pre) => !pre);
                })();
            }
        }
    }, [instance.values.company.selectedKeys]);

    useEffect(() => {
        if (fetchedInstanceData !== null) {
            updateInstanceData(fetchedInstanceData);
        }
    }, [fetchedInstanceData]);

    const outerStackStyle = {
        root: {boxShadow: Depths.depth8},
    };

    const stackItemStyles = {
        root: {
            // minWidth: '200px',
            padding: 5,
            width: '100%',
        },
    };

    const verticalGapStackTokens = {
        childrenGap: 10,
        padding: 20,
    };

    if (instance.isActive && !instance.isDone) {
        return (
            <Stack
                horizontal
                wrap
                tokens={verticalGapStackTokens}
                styles={outerStackStyle}
            >
                <Label className="df-stack-header">
                    <Icon iconName={'ChevronDownMed'}/>&nbsp;
                    {instance.identifier}
                </Label>
                <Stack
                    horizontal
                    wrap
                    tokens={verticalGapStackTokens}
                    className={'df-inner-form-stack'}
                >
                    <Stack.Item styles={stackItemStyles}>
                        <VirtualizedCombobox
                            label="Firma:"
                            values={instance.values.company}
                            name="company"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <MultiSelectDropdown
                            label="Ansprechpartner:"
                            values={instance.values.contact_person}
                            name="contact_person"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={lastCompanyKey === undefined || isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <MultiSelectDropdown
                            label="Kontakt durch:"
                            values={instance.values.consultant}
                            name="consultant"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={lastCompanyKey === undefined || isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <MultiSelectDropdown
                            label="Aufträge:"
                            values={instance.values.order}
                            name="order"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={lastCompanyKey === undefined || isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <MultiSelectDropdown
                            label="Akquisen:"
                            values={instance.values.acquisition}
                            name="acquisition"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={lastCompanyKey === undefined || isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <MultiSelectDropdown
                            label="Produkte:"
                            values={instance.values.product}
                            name="product"
                            handleChange={updateFormInstanceDropdownValue}
                            identifier={instance.identifier}
                            disabled={lastCompanyKey === undefined || isFetching}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <PrimaryButton
                            text="Speichern"
                            onClick={() => postInstanceToServer(instance.identifier)}
                            allowDisabledFocus
                            disabled={false}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    } else {
        if (!instance.isActive && !instance.isDone) {
            return (
                <Stack
                    horizontal
                    wrap
                    tokens={verticalGapStackTokens}
                    className="df-inner-stack"
                >
                    {/* {JSON.stringify(instance)} */}
                    <Stack.Item styles={stackItemStyles} onClick={() => setActive()}>
                        <Label className="df-stack-header clickable">
                            <Icon iconName={'ChevronRightMed'}/>&nbsp;
                            {instance.identifier}
                        </Label>
                    </Stack.Item>
                </Stack>
            );
        } else if (instance.isDone) {
            return (
                <Stack horizontal wrap tokens={verticalGapStackTokens} className="df-inner-stack">
                    <Label className="df-stack-header history-done">
                        <Icon iconName={'CheckboxComposite'}/>&nbsp;
                        {instance.identifier}
                    </Label>
                </Stack>
            );
        }
    }
}

export default InnerForm;
