import {useEffect, useState} from 'react';
import {useOfficeContext} from '../contexts/OfficeContext';
import {TextField} from '@fluentui/react/lib/TextField';
import useContactHistoryForm from '../hooks/useContactHistoryForm';
import InnerForm from './InnerForm';
import useJobnetApi from '../hooks/useJobnetApi';
import {useUserContext} from '../contexts/UserContext';
import {Label, Stack} from '@fluentui/react';
import {Separator} from '@fluentui/react/lib/Separator';
import Attachment from '../components/Attachment'

const stackItemStyles = {
    root: {
        padding: 5,
        paddingTop: 15
    },
};

const verticalGapStackTokens = {
    childrenGap: 10,
    padding: 30,
};

function OuterForm() {
    const {message} = useOfficeContext();
    const {subject, body, dateTime, instances, attachments} = useContactHistoryForm();
    const {jobnetAPI} = useJobnetApi();
    const {userContext, onAuthMissing} = useUserContext();
    const [initialRequestResult, setInitialRequestResult] = useState(null);


    function getItemBodyAsync() {
        return new Promise((resolve, reject) => {
            try {
                message.item.body.getAsync('text', {}, function callback(result) {
                    let body = result.value
                        .replace(/(<([^>]+)>)/gi, '')
                        .replace(/\t/gi, ' ')
                        .replace(/[ ]+/gi, ' ')
                        .replace(/ \r\n/gi, '\r\n')
                        .replace(/[\r\n]{2,}/gi, '\r\n\r\n')
                        .replace(/^[\r\n]+/gi, '');
                    return resolve(body);
                });
            } catch (e) {
                return reject(e);
            }
        });
    }


    useEffect(() => {
        (async () => {
            const itemBody = await getItemBodyAsync();
            const fetchedData = await jobnetAPI.findMessageById(
                message.item.internetMessageId,
                userContext.access_token, onAuthMissing
            );
            setInitialRequestResult(() => fetchedData);
            body.setValue(itemBody);
            subject.setValue(message.item.subject);
            dateTime.setValue(message.item.dateTimeCreated);
            attachments.setValue(message.item.attachments)
        })();
    }, [message.item]);

    useEffect(() => {
        if (initialRequestResult !== null) {
            instances.addFormInstances(
                initialRequestResult,
                message.item.emailAddresses,
                message.item.internetMessageId
            );
        }
    }, [initialRequestResult]);

    if (initialRequestResult === null) {
        return <div>Loading...</div>;
    } else if (typeof message.item !== 'undefined') {
        return (
            <div>
                <Stack tokens={verticalGapStackTokens}>
                    <Stack>
                        <Stack.Item styles={stackItemStyles}>
                            <TextField
                                label="Betreff"
                                value={subject.value}
                                onChange={(e) => subject.setValue(e)}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <TextField
                                label="E-Mail-Text"
                                multiline
                                rows={6}
                                value={body.value}
                                onChange={(e) => body.setValue(e)}
                            />
                        </Stack.Item>
                        {attachments.value.length > 0 && (
                            <>
                                <Stack.Item styles={stackItemStyles}>
                                    <Label>Anhänge</Label>
                                </Stack.Item>
                                <Stack.Item styles={stackItemStyles}>
                                    {attachments.value.map((attachment) => (
                                        <>
                                            <Attachment name={attachment.name} key={attachment.id}
                                                        attachmentType={attachment.attachmentType} id={attachment.id}
                                                        handleChange={attachments.setValue}/>
                                            <Separator/>
                                        </>
                                    ))}
                                </Stack.Item>
                            </>
                        )}
                        <Stack.Item styles={stackItemStyles}>
                            <Label>Beteiligte E-Mail-Adressen:</Label>
                        </Stack.Item>
                    </Stack>
                    {instances.value.length > 0 &&
                        instances.value.map((instance) => (
                            <>
                                <InnerForm
                                    instance={instance}
                                    key={message.item.internetMessageId + instance.identifier}
                                    theKey={message.item.internetMessageId + instance.identifier}
                                    setActive={instances.updateActiveInstance(instance.identifier)}
                                    updateInstanceData={instances.updateFormInstanceValues}
                                    updateFormInstanceDropdownValue={
                                        instances.updateFormInstanceDropdownValue
                                    }
                                    postInstanceToServer={instances.postInstanceToServer}
                                />
                            </>
                        ))}
                </Stack>
            </div>
        );
    } else {
        return <div>Bitte eine E-Mail auswählen...</div>;
    }
}

export default OuterForm;
