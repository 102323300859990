function useJobnetAuth() {
    // Login
    const login = async (username, password) => {
        const reqBody = JSON.stringify({
            username: username,
            password: password,
            type: "outlook",
        });

        const res = await fetch("https://api.job-server.net/v1/consultant/login", {
            mode: 'no-cors',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: reqBody,
        });

        const data = await res.json();

        if (res.status === 200) {
            return {status: "OK", ...data};
        } else {
            return {status: "ERROR", ...data};
        }
    };

    // test if loggedIn
    const test = async (access_token) => {
        const res = await fetch("https://api.job-server.net/v1/test_login", {
            mode: 'no-cors',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
            },
            method: "GET",
        });

        const data = await res.json();

        if (res.status === 200) {
            return {status: "OK"};
        } else {
            return {status: "ERROR", error_code: data.error_code};
        }
    };

    // use refresh_token to renew access
    const refresh = async (refresh_token) => {
        const reqData = JSON.stringify({token: refresh_token});
        const res = await fetch("https://api.job-server.net/v1/refresh_token", {
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            method: "PUT",
            body: reqData,
        });

        const data = await res.json();

        if ((res.status === 200)) {
            return {status: "OK"};
        } else {
            return {status: "ERROR", error_code: data.error_code};
        }
    };

    return {
        jobnetAuth: {login, test, refresh},
    };
}

export default useJobnetAuth;
